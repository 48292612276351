<template>
  <!--  :style="{ 'padding-left': getScrollYWidth + 'px' }" -->
  <div v-loading="loading" class="CreateThirdSheet">
    <templateHeader :exam-information="examInformation"></templateHeader>
    <!-- 选择基础信息页面 -->
    <template v-if="indexViews == 1">
      <div class="updata-parameter">
        <p class="updata-parameter-title" style="padding-top: 6px">
          填写三方卡信息
        </p>
        <div>
          <el-form
            ref="ruleForm"
            :model="sheetData"
            :rules="rules"
            label-width="94px"
            class="demo-ruleForm"
          >
            <el-form-item label="模板名称：" prop="name">
              <el-input
                v-model="sheetData.name"
                placeholder="请输入"
                maxlength="50"
                show-word-limit
                style="width: 320px"
                class="text-nums"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="所属科目："
              prop="subjectId"
              class="subject-name"
            >
              <el-radio-group v-model="sheetData.subjectId">
                <el-radio
                  v-for="(v, i) in subjectList"
                  :key="i"
                  :label="v.value"
                >
                  {{ v.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="适用学年：" prop="year">
              <el-select v-model="sheetData.year" placeholder="请选择">
                <el-option
                  v-for="item in yearList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="适用学段：" prop="levelId">
              <el-select v-model="sheetData.levelId" placeholder="学段">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="updata-parameter">
        <p class="updata-parameter-title">选择答题卡规格</p>
        <div>
          <div class="updata-parameter-item">
            <span> 尺寸：</span>
            <el-radio-group v-model="sheetData.paperType">
              <el-radio
                v-for="(v, i) in paperTypeList"
                :key="i"
                :label="v.value"
                >{{ v.label }}</el-radio
              >
            </el-radio-group>
          </div>
          <div class="updata-parameter-item">
            <span> 样式：</span>
            <el-radio-group v-model="sheetData.colorType">
              <el-radio
                v-for="(v, i) in colorTypeList"
                :key="i"
                :label="v.value"
              >
                {{ v.label }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="sheetData.colorType == 2" class="updata-parameter-item">
            <span> 增强模式：</span>
            <el-checkbox v-model="isHeighten" :true-label="1" :false-label="2">
            </el-checkbox>
          </div>
          <div class="updata-parameter-item">
            <span> 张数：</span>
            <el-radio-group v-model="sheetData.numberCount">
              <el-radio
                v-for="(v, i) in numberCountList"
                :key="i"
                :label="v.value"
                >{{ v.label }}</el-radio
              >
            </el-radio-group>
          </div>
          <div class="updata-parameter-item">
            <span> 面数：</span>
            <el-radio-group v-model="sheetData.pageCount">
              <template v-if="sheetData.numberCount">
                <template v-for="(v, i) in pageCountList">
                  <el-radio
                    v-if="i + 1 <= sheetData.numberCount * 2"
                    :key="i"
                    v-model="sheetData.pageCount"
                    :label="v.value"
                  >
                    {{ v.label }}
                  </el-radio>
                </template>
              </template>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="unimg-box">
        <p class="unimg-box-title">
          上传或扫描答题卡 <span>注意：请上传或扫描“未作答”的答题卡</span>
        </p>
        <div class="check-img-box">
          <div class="check-img-list">
            <div
              v-loading="clientLoading"
              class="img-box"
              @click="sendMsg(true)"
            >
              <img src="@/static/exam/clientImg.png" alt="" />
              <span>扫描纸质答题卡</span>
            </div>
            <div class="check-tips-list">
              状态：
              <span v-if="clientSideLinkType" class="succ">
                扫描仪连接成功
              </span>
              <!-- <span v-if="clientSideLinkType" class="err"
                  >扫描仪状态检测中</span
                > -->
              <template v-else>
                <span v-if="!clientSideType" class="err">
                  <span v-if="returnMsg" class="waring">{{ returnMsg }}</span>
                  <template v-else>扫描客户端未运行</template>
                </span>
                <span v-else class="succ" @click="downCleint">连接成功</span>
              </template>
            </div>
            <div class="check-tips-list">
              此处：<span class="succ" @click="downCleint">下载扫描客户端</span>
            </div>
            <div class="check-tips-list">注意：请依次放置答题卡</div>
          </div>
          <div
            class="check-img-list"
            :class="{
              'show-upload': lintFrom(1),
            }"
            @click="lintFrom()"
          >
            <el-upload
              class="img-box"
              multiple
              :auto-upload="false"
              accept="image/jpeg,image/jpg,image/png"
              :action="examanswerModifyURL"
              :headers="uploadHeaders"
              :on-change="uploadChange"
              :on-success="batchModifyOnSuccess"
              :on-before="handleImportBefore"
              :on-error="batchModifyOnError"
              :on-progress="batchModifyOnProgress"
              :show-file-list="false"
              :file-list="fileList"
              :limit="sheetData.pageCount"
              :on-exceed="exceedChange"
            >
              <img src="@/static/exam/sheetImg.png" alt="" />
              <span>上传本地图片</span>
            </el-upload>
            <!-- <div class="img-box">
  
            </div> -->
            <div class="check-tips-list">注意：请上传jpg,png格式文件</div>
          </div>
        </div>
      </div>
    </template>
    <!-- 组合页面 -->
    <template v-if="indexViews == 2">
      <div class="show-img-box">
        <div class="show-img-item">
          <div class="show-img-title">答题卡规格</div>
          <div>
            {{ sheetData.paperType | setName(paperTypeList) }}.{{
              sheetData.colorType | setName(colorTypeList)
            }}
            {{ sheetData.numberCount | setName(numberCountList)
            }}{{ sheetData.pageCount | setName(pageCountList) }}
          </div>
        </div>
        <div class="show-img-item">
          <div class="show-img-title" style="margin-top: 12px">
            答题卡预览
            <!-- <p class="show-img-title-tips">点击图片查看大图</p> -->
          </div>
          <div class="img-item-box">
            <div
              v-for="(item, index) in fileChangeList"
              :key="index"
              class="img-item"
              draggable="true"
              @dragstart="handleDragStart($event, item)"
              @dragover.prevent="handleDragOver($event, item)"
              @dragenter="handleDragEnter($event, item)"
              @dragend="handleDragEnd($event, item)"
            >
              <el-image
                :key="imgKey"
                :src="item"
                :preview-src-list="fileChangeList"
              >
              </el-image>
              <div class="i-box" @click="rotateImg(item, index)">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="旋转90°"
                  placement="bottom"
                >
                  <i class="el-icon-refresh"></i>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="img-item-box intro-box">
            <div v-if="fileChangeList.length > 0" class="img-item">
              第一张正面
            </div>
            <div v-if="fileChangeList.length > 1" class="img-item">
              第一张反面
            </div>
            <div v-if="fileChangeList.length > 2" class="img-item">
              第二张正面
            </div>
            <div v-if="fileChangeList.length > 3" class="img-item">
              第二张反面
            </div>
          </div>
        </div>
        <div class="btn-box">
          <el-button type="text" @click="rotateAllImg">全部旋转90度</el-button>
        </div>
        <div class="btn-box">
          <el-checkbox v-model="templateTrue">手动画模板</el-checkbox>
        </div>
        <div class="btn-box">
          <el-button plain @click="checkToOne">重新上传/扫描</el-button>
          <el-button type="primary" :loading="btnLoading" @click="submit"
            >确定</el-button
          >
        </div>
        <div class="all-tips">
          <p>注意：</p>

          1、请确保答题卡图片方向正确，如需调整，请旋转图片；
          <br />
          2、请确保答题卡图片依次排列，如需调整，请拖动图片对调顺序；
          <br />
          3、点击图片可查看大图；
          <br />
          4、默认自动制作模版，支持框选定位信息、标题、考号、客观题；
          <br />
          5、如需手动制作模版，请勾选“手动画模版”；
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import templateHeader from "../component/templateHeader.vue";
import { mapGetters } from "vuex";
import { getStore } from "@/core/util/store";
import { websocketCommand } from "@/core/util/websocket.js";
import { levelOptions } from "@/core/util/constdata";
import { sheetUpload, addExamPapersheet } from "@/core/api/exam/sheet";
import { downClient } from "@/core/util/util.js";
import { getSubjectList, getYear } from "@/core/util/util";
export default {
  name: "CreateThirdSheet",
  components: {
    templateHeader,
  },

  data() {
    return {
      //扫描状态
      clientSideType: false,
      clientLoading: false,
      clientSideLinkType: false,
      templateTrue: true,
      loading: false,
      btnLoading: false,
      isHeighten: 1,
      scannerStatus: undefined,
      tipsArr: ["未连接扫描仪", "扫描仪无纸张", "扫描仪错误"],
      indexViews: 1,
      pageCountList: [
        {
          value: 1,
          label: "1面",
        },
        {
          value: 2,
          label: "2面",
        },
        {
          value: 3,
          label: "3面",
        },
        {
          value: 4,
          label: "4面",
        },
        {
          value: 5,
          label: "5面",
        },
        {
          value: 6,
          label: "6面",
        },
        {
          value: 7,
          label: "7面",
        },
        {
          value: 8,
          label: "8面",
        },
        {
          value: 9,
          label: "9面",
        },
        {
          value: 10,
          label: "10面",
        },
      ],
      paperTypeList: [
        {
          value: 1,
          label: "A4",
        },
        {
          value: 2,
          label: "A3",
        },
      ],
      colorTypeList: [
        {
          value: 1,
          label: "黑卡",
        },
        {
          value: 2,
          label: "红卡",
        },
      ],
      numberCountList: [
        {
          value: 1,
          label: "1张",
        },
        {
          value: 2,
          label: "2张",
        },
        {
          value: 3,
          label: "3张",
        },
        {
          value: 4,
          label: "4张",
        },
        {
          value: 5,
          label: "5张",
        },
      ],
      examInformation: {
        examName: "创建答题卡",
      },
      rules: {
        subjectId: [
          { required: true, message: "请选择所属科目", trigger: "change" },
        ],
        year: [
          { required: true, message: "请选择适用学年", trigger: "change" },
        ],
        levelId: [
          { required: true, message: "请选择适用学段", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
      },
      sheetData: {
        paperType: 2,
        colorType: 1,
        numberCount: 1,
        pageCount: 2,
        name: "",
        subjectId: "",
        year: "",
        levelId: "",
      },
      examanswerModifyURL: "",
      uploadHeaders: {
        Authorization: "",
      },
      fileList: [],
      fileChangeList: [],
      imgKey: 1,
      returnMsg: "",
      subjectList: getSubjectList(),
      levelOptions: levelOptions(),
      yearList: getYear(),
    };
  },
  computed: {
    ...mapGetters(["getScrollYWidth"]),
  },
  created() {
    this.uploadHeaders.Authorization =
      "Bearer " + getStore({ name: "access_token" });
    this.examanswerModifyURL = "/exam/sheet/" + new Date().getFullYear();
    this.createWs();
  },
  destroyed() {
    websocketCommand(this.wsObj, "close");
  },
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert('submit!');
        } else {
          return false;
        }
      });
    },
    downCleint() {
      downClient();
      // const file =
      //   "https://static.wtjy.com/installer/scanner/202209/WenTongScanner_1.0.05_2022092901.exe";
      // // fileDownloadByUrl(file, "文通云扫描客户端");
      // window.open(file, "_blank");
    },
    // webscokt
    createWs() {
      this.clientSideType = false;
      this.clientSideLinkType = false;
      // this.wsObj = new WebSocket("wss://scan.zhixue.cn:9468/zxwscans");
      this.wsObj = new WebSocket("ws://127.0.0.1:9468/wtyscans");
      // this.wsObj = new WebSocket("ws://192.168.12.105:9468/wtyscans");
      // 若为字符串类型，直接传参
      // console.log("1111111111");
      this.sendObj = {
        action: "communicationScan",
      };
      websocketCommand(
        this.wsObj,
        "create",
        5000,
        this.sendHeartBeat,
        this.receiveMsg,
        this.reconnectWs,
        this.sendObj
      );
    },
    //     0 未连接扫描仪
    // 1 扫描仪无纸张
    // 2 扫描仪错误
    // 3 已连接扫描仪
    // 4 扫描上传中
    // 5 扫描完成
    // 6 校验扫描上传完成
    // 7 扫描上传失败
    // 8 扫描取消
    receiveMsg(data) {
      this.returnMsg = "";
      this.btnDisabled = false;
      if (data.returnCode == 200) {
        if (data.scannerStatus == 0) {
          this.returnMsg = "未连接扫描仪";
          this.clientLoading = false;
          this.clientSideType = false;
          this.btnDisabled = true;
        }
        if (data.scannerStatus == 1) {
          this.returnMsg = "扫描仪无纸张";
          this.clientLoading = false;
          this.$alert(`扫描仪无纸张`, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.clientLoading = false;
              this.clientSideType = true;
            },
          });
        }
        if (data.scannerStatus == 2) {
          this.returnMsg = "扫描仪错误";
          this.btnDisabled = true;
          this.clientLoading = false;
          this.clientSideType = false;
        }
        // 已连接扫描仪
        if (data.scannerStatus == 3) {
          this.btnDisabled = false;
          this.clientSideType = true;
          this.clientSideLinkType = true;
          this.clientSideId = data.clientId;
        }
        if (data.scannerStatus == 5) {
          // if (this.indexReq != 2) {
          this.clientLoading = false;
          this.$message({
            message: `扫描完成!`,
            type: "success",
            duration: 2000,
            showClose: true,
          });
          // }
        }
        if (data.scannerStatus == 6) {
          this.fileChangeList = [];
          data.images.split("，").forEach((item) => {
            this.fileChangeList.push(item + "?" + new Date().getTime());
          });
          this.clientLoading = false;
          this.checkViews();
        }
        if (data.scannerStatus == 7) {
          this.$message({
            showClose: true,
            message: `扫描上传失败`,
            type: "error",
            duration: 3000,
          });
          this.clientLoading = false;
        }
        if (data.scannerStatus == 8) {
          this.$message({
            showClose: true,
            message: `扫描已取消`,
            type: "success",
          });
          this.clientLoading = false;
        }
        if (data.scannerStatus == 9) {
          this.$alert(`磁盘空间低于500M，请清理后上传！`, "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          // this.btnDisabled = true;
          this.clientLoading = false;
          this.clientSideType = false;
        }
      } else {
        this.clientSideType = false;
        this.clientSideLinkType = false;
        this.clientLoading = false;
        this.btnDisabled = true;
        if (!data.returnCode) {
          return;
        }

        let str = "";
        if (data.returnCode == 201) {
          str = "未识别的action";
        }
        if (data.returnCode == 202) {
          str = "请求参数错误";
        }
        if (data.returnCode == 203) {
          str = "参数解析错误";
        }
        if (data.returnCode == 204) {
          str = "扫描任务正在进行中，请稍后！";
        }
        this.$alert(`${str}，请联系管理员！`, "提示", {
          confirmButtonText: "确定",
          callback: () => {},
        });
      }
    },
    // receiveMsg(data) {
    //   // console.log("服务端推送", data);
    //   // console.log("服务端推送", data.returnCode);
    //   if (data.returnCode == 200) {
    //     if (this.isFalse) {
    //       clearTimeout(this.isFalse);
    //     }
    //     this.clientSideType = true;
    //     this.clientSideLinkType = true;
    //     this.clientSideId = data.clientId;
    //     if (this.scannerStatus != data.scannerStatus) {
    //       if (data.scannerStatus == 0) {
    //         this.$message({
    //           message: data.message,
    //           type: "success",
    //           duration: 2000,
    //         });
    //       }
    //     }
    //     this.scannerStatus = data.scannerStatus;
    //     // console.log(this.clientSideId);
    //     if (data.images) {
    //       this.fileChangeList = [];
    //       data.images.split("，").forEach((item) => {
    //         this.fileChangeList.push(item + "?" + new Date().getTime());
    //       });
    //       //  = ;
    //       this.clientLoading = false;
    //       this.checkViews();
    //       // this.dialogVisible = true;
    //     }
    //     if (this.sendObj.action != "communicationScan") {
    //       this.sendObj = {
    //         action: "communicationScan",
    //       };
    //       websocketCommand(
    //         this.wsObj,
    //         "push",
    //         5000,
    //         this.sendHeartBeat,
    //         this.receiveMsg,
    //         this.reconnectWs,
    //         this.sendObj
    //       );
    //     }
    //   } else {
    //     // console.log("进了");
    //     // this.clientSideType = false;
    //     // this.clientSideLinkType = false;
    //     // this.clientLoading = false;
    //     // if (data.returnCode == 500) {
    //     //   this.$message.error(data.message);
    //     // }
    //     // if (data.returnCode != 500) {
    //     //   this.returnMsg = data.message;
    //     // }
    //     // 延迟一下  等待重连 判断  如果重新请求失败  在显示状态
    //     this.isFalse = setTimeout(() => {
    //       this.clientSideType = false;
    //       this.clientSideLinkType = false;
    //       this.clientLoading = false;
    //     }, 800);
    //     if (data.returnCode == 500) {
    //       this.$message.error(data.message);
    //     }
    //     if (data.returnCode != 500 && data.returnCode != 203) {
    //       this.returnMsg = data.message;
    //     }
    //     if (data.returnCode == 203 && data.scannerStatus != 1) {
    //       this.returnMsg = "扫描仪连接异常";
    //     }
    //     // console.log(data.returnCode);
    //     // console.log(data.scannerStatus);
    //     if (data.returnCode == 203 && data.scannerStatus == 1) {
    //       this.sendObj = {
    //         action: "communicationScan",
    //       };
    //       websocketCommand(
    //         this.wsObj,
    //         "push",
    //         5000,
    //         this.sendHeartBeat,
    //         this.receiveMsg,
    //         this.reconnectWs,
    //         this.sendObj
    //       );
    //       this.$alert("未找到纸张或扫描仪卡纸", "提示", {
    //         confirmButtonText: "确定",
    //         callback: () => {
    //           this.clientLoading = false;
    //           this.clientSideType = true;
    //         },
    //       });
    //     }
    //     // this.$message.error();
    //   }
    // },
    // 断网重连，需要处理的逻辑
    reconnectWs() {
      if (this.wsObj) {
        // console.log("%c websocket_reconnect", "color:blue");
        this.createWs();
        // todo
        // 如：this.$store.dispatch("app/changeAppMsg",['cloudWebsocketReconnect',true]);
      }
    },
    // 以回调方式向服务端发送(对象类型的)心跳
    sendHeartBeat() {
      this.wsObj.send(JSON.stringify(this.sendObj));
    },
    lintFrom(type) {
      if (!type) {
        this.submitForm();
      }

      if (
        !this.sheetData.name ||
        !this.sheetData.levelId ||
        !this.sheetData.year ||
        !this.sheetData.subjectId
      ) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        return true;
      }

      return false;
    },
    sendMsg() {
      if (this.lintFrom()) {
        return;
      }
      if (!this.clientSideType || !this.clientSideLinkType) {
        this.$message({
          message: "扫描仪状态异常，请先检查！",
          type: "warning",
          duration: 2000,
        });
        return;
      }
      // console.log(this.clientSideType, this.clientSideLinkType);
      // this.wsObj = new WebSocket("ws://127.0.0.1:9468/wtyscan");
      // colorType 1 黑卡 2红卡
      // isHeighten 1 增强 2不增强
      const token = getStore({ name: "access_token" });
      const schoolId = getStore({ name: "school_id" });
      this.sendObj = {
        action: "startScan",
        data: {
          token: token,
          schoolId: schoolId,
          examPaperId: new Date().getTime(),
          firstToExamine: 1,
          pageCount: this.sheetData.pageCount,
          type: this.sheetData.paperType == 1 ? "A4" : "A3",
          colorType: this.sheetData.colorType,
          isHeighten: this.isHeighten,
          drivePanel: 1,
        },
      };
      // sheetData.colorType == 2
      this.clientLoading = true;
      setTimeout(() => {
        this.clientLoading = false;
      }, 10000);
      websocketCommand(
        this.wsObj,
        "push",
        5000,
        this.sendHeartBeat,
        this.receiveMsg,
        this.reconnectWs,
        this.sendObj
      );
    },
    // end webscokt
    submit() {
      //   this.sheetData.name = this.examInformation.examName;
      //   this.sheetData.examPaperId = this.examInformation.paperId;
      //   this.sheetData.subjectId = this.examInformation.subjectId;
      // this.sheetData.isHeighten = this.isHeighten;
      this.sheetData.type = 1;
      this.btnLoading = true;
      addExamPapersheet(this.sheetData)
        .then((res) => {
          // console.log(res);
          let data = {
            sheetId: res.data.data,
            type: "firistCreate",
            from: "SheetList",
            // examId: this.examInformation.examId,
          };

          const routeData = this.$router.resolve({
            name: "ThirdPartyAnswerSheet",
            query: data,
          });
          window.open(routeData.href, "_blank");
          this.$router.go(-1);

          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
      // console.log(this.sheetData);
    },
    // 返回上一步
    checkToOne() {
      this.indexViews = 1;
      this.fileChangeList = [];
      this.fileList = [];
    },
    // 旋转所有img
    rotateAllImg() {
      this.fileChangeList.forEach((item, index) => {
        this.rotateImg(item, index);
      });
      // this.checkViews();
    },
    // 旋转img
    rotateImg(v, index) {
      let imgurl = v;
      let newImgUrl = "";
      let rotateList = imgurl.split(",");
      // 如果是旋转过的
      if (rotateList.length > 1) {
        let rotate = Number(rotateList[1]);
        // rotateList
        if (rotate < 270) {
          newImgUrl = rotateList[0] + "," + (rotate + 90);
        } else {
          newImgUrl = imgurl.split("?")[0];
        }
      } else {
        let img = imgurl.split("?")[0];
        newImgUrl = img + "?x-oss-process=image/rotate,90";
      }
      this.fileChangeList[index] = newImgUrl;
      // });
      this.$nextTick(() => {
        this.fileChangeList = [...this.fileChangeList];
      });
      this.checkViews();
    },
    // 拖动start
    handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd() {
      this.dragging = null;
    },
    //首先把div变成可以放置的元素，即重写dragenter/dragover
    handleDragOver(e) {
      e.dataTransfer.dropEffect = "move"; // e.dataTransfer.dropEffect="move";//在dragenter中针对放置目标来设置!
    },
    handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = "move"; //为需要移动的元素设置dragstart事件
      if (item === this.dragging) {
        return;
      }
      const newItems = this.fileChangeList;
      // console.log(newItems);
      const src = newItems.indexOf(this.dragging);
      const dst = newItems.indexOf(item);

      newItems.splice(dst, 0, ...newItems.splice(src, 1));

      this.fileChangeList = newItems;
      this.checkViews();
    },
    //  拖动 end
    checkViews() {
      this.indexViews = 2;
      this.sheetData.urls = this.fileChangeList.join("，");
    },
    // 上传图片 start
    exceedChange() {
      this.fileList = [];
      this.$message.error(`最多上传${this.sheetData.pageCount}张图片`);
    },
    handleImportFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    uploadChange(file, fileList) {
      setTimeout(() => {
        this.uploadImg(fileList);
      }, 500);
    },
    sheetUpload(file) {
      let year = new Date().getFullYear();
      let formData = new window.FormData();
      formData.append("file", file);
      this.loading = true;
      sheetUpload(year, formData)
        .then((res) => {
          this.fileChangeList.push(res.data.data.url);
          if (this.fileChangeList.length == this.sheetData.pageCount) {
            // 如果图片上传完成  切换到新页面
            this.checkViews();
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.fileList = [];
          this.fileChangeList = [];
        });
    },
    uploadImg(fileList) {
      let date = new Date().getTime();

      if (!this.date || date - this.date > 500) {
        // console.log(date - this.date);
        this.date = new Date().getTime();
        if (!this.sheetData.pageCount || !this.sheetData.numberCount) {
          this.$message.error(`请选先选择答题卡参数！`);
          this.fileList = [];
          return;
        }

        if (fileList.length == this.sheetData.pageCount) {
          fileList.forEach((item) => {
            this.sheetUpload(item.raw);
          });
        } else {
          this.fileList = [];
          this.$message.error(`请选择${this.sheetData.pageCount}张图片`);
        }
      }
    },
    handleImportBefore(file) {
      let is10M = file.size / 1024 / 1024 <= 100;
      if (!is10M) {
        this.$message.error("文件大小不能超过 100 MB！");
        return false;
      }

      return false;
    },
    batchModifyOnSuccess(response, file, fileList) {
      this.loading = false;
      this.fileItem.push({
        url: response.data.url,
      });
      this.handleImportFileRemove(fileList);
    },
    batchModifyOnError(err, file, fileList) {
      this.loading = false;
      this.handleImportFileRemove(fileList);
    },
    batchModifyOnProgress() {
      this.batchAmendingVisible = false;
      this.loading = true;
    },
    // 上传图片 end
    goSheet() {},
  },
};
</script>

<style lang="scss" scoped>
.CreateThirdSheet {
  .show-img-box {
    padding: 100px 0 0 350px;
    .all-tips {
      padding-left: 145px;
      color: #525252;
    }
    .btn-box {
      padding-left: 145px;
      margin-bottom: 40px;
    }
    .show-img-item {
      display: flex;
      margin-bottom: 50px;
      flex-wrap: wrap;
      .img-item-box.intro-box {
        padding-left: 145px;
        width: 100%;
        .img-item {
          background: #ffffff;
          text-align: center;
        }
      }
      .img-item-box {
        display: flex;
        flex-wrap: wrap;
        .i-box {
          position: absolute;
          cursor: pointer;
          right: 14px;
          bottom: 14px;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 2px;
          line-height: 14px;
          padding: 5px;
          color: #ffffff;
          i {
            font-size: 14px;
          }
          // &:last-child {
          //   right: 50px;
          // }
        }
        .img-item {
          width: 250px;
          position: relative;
          width: 250px;
          padding: 14px;
          background: #f8f7f8;
          margin-right: 24px;
          margin-top: 12px;
          img {
            width: 100%;
          }
        }

        // > div {

        // }
      }
      .show-img-title {
        margin-right: 70px;
        position: relative;
        .show-img-title-tips {
          font-size: 12px;
          color: $primary-color;
          position: absolute;
          left: 0;
          top: 20px;
          width: 105px;
        }
      }
    }
  }
  .unimg-box {
    padding: 50px 300px 0;
    .check-img-box {
      padding-left: 156px;
      display: flex;
      margin-top: 46px;
      .show-upload {
        .img-box {
          pointer-events: none;
        }
      }
      .check-img-list {
        .check-tips-list {
          margin-top: 30px;
          .waring {
            color: #f0bd62;
          }
          .err {
            color: #f86e6f;
          }
          .succ {
            color: $primary-color;
            cursor: pointer;
          }
        }
        .img-box {
          padding: 36px;
          text-align: center;
          border: 1px solid $primary-color;
          margin-right: 100px;
          cursor: pointer;
          span {
            margin-top: 20px;
            display: block;
            color: #080a09;
          }
        }
      }
    }
    .unimg-box-title {
      span {
        margin-left: 30px;
        color: #f86e6f;
      }

      font-weight: 500;
    }
  }
  .updata-parameter {
    padding: 40px 300px 0;
    display: flex;
    color: #525252;
    .subject-name {
      ::v-deep {
        .el-form-item__label {
          line-height: 14px !important;
        }
      }
    }
    .el-form-item {
      &:last-child {
        margin-bottom: 0;
      }
      ::v-deep {
        .el-form-item__label {
          padding: 0;
          text-align: left;
          margin-bottom: 0;
          line-height: 32px;
        }
        .el-form-item__content {
          line-height: 32px;
        }
      }
      .el-radio-group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 365px;
        row-gap: 20px;
        ::v-deep {
          .el-radio__label {
            padding-left: 5px;
          }
        }
        .el-radio {
          margin: 0;
        }
      }
    }
    .updata-parameter-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        line-height: 32px;
      }
      > span {
        display: block;
        width: 94px;
      }
      .el-radio-group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 365px;
        row-gap: 20px;
        ::v-deep {
          .el-radio__label {
            padding-left: 5px;
          }
        }
        .el-radio {
          margin: 0;
        }
      }
    }
    // .el-radio {
    //   margin: 0 0 40px 50px;
    // }
    .updata-parameter-tips {
      margin-left: 50px;
    }
    .updata-parameter-err {
      margin-left: 50px;
      color: #f86e6f;
    }
    //  updata-parameter-tips updata-parameter-err
    .updata-parameter-title {
      margin-right: 50px;
      font-weight: 500;
    }
  }
}
</style>
